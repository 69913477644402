<template>
    <div class="slider-cell">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "SliderCell",
}
</script>

<style scoped>
.slider-cell {
    flex: none;
}
</style>