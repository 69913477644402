<template>
    <div :id=id class="wrapper">
        <span class="main" v-text="mainText"></span>
        <span class="sub" v-text="subText"></span>
    </div>
</template>

<script>
export default {
    name: "SecTitle",
    props: {
        id: {
            type: String,
            required: true
        },
        mainText: {
            type: String,
            required: true
        },
        subText: {
            type: String,
            required: true
        },
        color: {
            type: String,
            required: true,
        },
    },
    mounted() {
        this.judgeAnime();
        window.addEventListener('scroll', this.judgeAnime);
    },
    methods: {
        judgeAnime() {
            const wrapper = document.querySelector(`#${this.id}.wrapper`);
            const rect = wrapper.getBoundingClientRect();
            const scroll = window.pageYOffset || document.documentElement.scrollTop;
            const offset = rect.top + scroll;
            const windowHeight = window.innerHeight;
            const hasAnimated = wrapper.classList.contains("is-animated");
            if (!hasAnimated && offset < scroll + windowHeight) {
                this.playSlideInAnime();
            }
        },
        playSlideInAnime() {
            const wrapper = document.querySelector(`#${this.id}.wrapper`);
            wrapper.classList.add("is-animated");
        }
    }
}
</script>

<style scoped>
.wrapper {
    border-bottom: solid 6px v-bind(color);
    margin-left: 100%;
    transition: .5s;
}

.main {
    font-size: 80px;
    line-height: 80px;
    margin-right: 33px;
    color: v-bind(color);
}

.sub {
    font-size: 24px;
    color: v-bind(color);
}

.wrapper.is-animated {
    margin-left: 0;
}

@media screen and (max-width: 1280px) {
    .wrapper {
        border-bottom-width: 3px;
    }

    .main {
        font-size: 30px;
        margin-right: 10px;
        line-height: 30px;
    }

    .sub {
        font-size: 10px;
    }
}
</style>