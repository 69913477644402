<template>
    <div :id=id class="hori-slider">
        <div class="slide-area">
            <slot></slot>
        </div>

        <img class="step-btn prev-btn" @click=slidePrev src="img/icon/horiSliderStepBtn.png">
        <img class="step-btn next-btn" @click=slideNext src="img/icon/horiSliderStepBtn.png">
    </div>
</template>

<script>
export default {
    name: "HoriSlider",
    props: {
        id: {
            type: String,
            required: true
        },
        gap: {
            type: String,
            required: true
        },
    },
    mounted() {
        const slideArea = this.getSlideArea();
        const slideAreaLeft = slideArea.offsetLeft;
        try {
            this.updateBtnVisible(slideAreaLeft);
            this.updateSlideAreaJustifyContent();
        } catch(e) {
            // console.log(e);
        }

        window.addEventListener("resize", () => {
            this.updateBtnVisible(slideAreaLeft);
            this.updateSlideAreaJustifyContent();
        });
    },
    updated() {
        const slideArea = this.getSlideArea();
        const slideAreaLeft = slideArea.offsetLeft;
        this.updateBtnVisible(slideAreaLeft);
        this.updateSlideAreaJustifyContent();
    },
    methods: {
        updateBtnVisible(slideAreaLeft) {
            const prevBtn = document.querySelector(`#${this.id} .prev-btn`);
            const nextBtn = document.querySelector(`#${this.id} .next-btn`);

            prevBtn.style.visibility = slideAreaLeft < 0 ? "visible" : "hidden";
            nextBtn.style.visibility = slideAreaLeft > this.slideAreaLeftMax()
                ? "visible" : "hidden";
        },
        updateSlideAreaJustifyContent() {
            const slideArea = this.getSlideArea();
            const slideAreaLeft = slideArea.offsetLeft;
            slideArea.style.justifyContent = slideAreaLeft > this.slideAreaLeftMax()
                ? "flex-start" : "center";
        },
        slidePrev() {
            const slideArea = this.getSlideArea();
            const slideAreaLeft = slideArea.offsetLeft;

            const cell = this.getCell();
            const slideCellWidth = cell.offsetWidth;

            if (slideAreaLeft < -slideCellWidth) {
                const cellCntInWindow = this.calcCellCntInWindow() / 2;
                const updatedLeft = Math.min(0, slideAreaLeft + slideCellWidth * cellCntInWindow);
                slideArea.style.left = updatedLeft + "px";

                this.updateBtnVisible(updatedLeft);
            }
        },
        slideNext() {
            const slideArea = this.getSlideArea();
            const slideAreaLeft = slideArea.offsetLeft;
            const slideAreaLeftMax = this.slideAreaLeftMax();
            if (slideAreaLeft > slideAreaLeftMax) {
                const cell = this.getCell();
                const cellWidth = cell.offsetWidth;
                const cellCntInWindow = this.calcCellCntInWindow() / 2;
                const updatedLeft = slideAreaLeft - cellWidth * cellCntInWindow;
                slideArea.style.left = updatedLeft + "px";

                this.updateBtnVisible(updatedLeft);
            }
        },
        slideAreaLeftMax() {
            const gap = parseInt(this.gap);
            const cellWidthSum = this.calcSlideAreaSum();
            const windowWidth = window.innerWidth;
            return -cellWidthSum + gap + windowWidth;
        },
        calcSlideAreaSum() {
            const cell = this.getCell();
            const cellWidth = cell.offsetWidth;
            const gap = parseInt(this.gap);
            const slideArea = this.getSlideArea();
            const childrenCnt = slideArea.childElementCount;
            return (cellWidth + gap) * childrenCnt;
        },
        getSlideArea() {
            return document.querySelector(`#${this.id} .slide-area`);
        },
        getCell() {
            return document.querySelector(`#${this.id} .slider-cell`);
        },
        calcCellCntInWindow() {
            const cell = this.getCell();
            const cellWidth = cell.offsetWidth;
            const gap = parseInt(this.gap);
            const windowWidth = window.innerWidth;
            return Math.floor((windowWidth + gap) / (cellWidth + gap));
        },
    },
}
</script>

<style scoped lang="scss">
.hori-slider {
    position: relative;
}

.slide-area {
    display: flex;
    justify-content: center;
    column-gap: v-bind(gap);
    position: relative;
    left: 0px;
    transition: .2s;
}

.prev-btn {
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(-50%) scaleX(-1);
    cursor: pointer;
}

.next-btn {
    position: absolute;
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.step-btn {
    /* area-linkより上 */
    z-index: 200;
    opacity: .3;
    transition: .2s;

    &:hover {
        opacity: 1;
    }
}

@media screen and (max-width: 1280px) {
    // 横スクロールできるようにする
    .hori-slider {
        margin: 0 -20px;
        padding-left:  20px;
        padding-right:  20px;
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .slide-area {
        align-items: start;
    }

    .step-btn {
        display: none;
    }
}
</style>
