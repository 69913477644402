<template>
    <HoriSlider :id=id :gap="cardGap">
        <SliderCell
            v-for="(data, i) in datas"
            :key="i"
            :id="data.id"
        >
            <IconTextColCard
                :iconPath=data.iconPath
                :text=data.text
                :href=data.href
            />
        </SliderCell>
    </HoriSlider>
</template>

<script>
import HoriSlider from '@/components/HoriSlider.vue';
import SliderCell from '@/components/SliderCell.vue';
import IconTextColCard from '@/components/IconTextColCard.vue';

export default {
    name: "IconTextColCardHoriSlider",
    components: {
        HoriSlider,
        SliderCell,
        IconTextColCard,
    },
    props: {
        id: {
            type: String,
            default: ""
        },
        datas: {
            type: Array,
            required: true
        }
    },
    computed: {
        cardGap() {
            return window.innerWidth <= 1280 ? "10px" : "66px";
        }
    }
}
</script>