<template>
    <CommonHeader/>

    <main>
        <video id="hero-area" playsinline autoplay loop muted disablepictureinpicture poster="homeHero/homeHeroThumbnail.png">
            <source src="homeHero/homeHero.mp4">
        </video>

        <ReqLink class="req-link" />

        <section id="news-sec">
            <SecTitle
                id="news-sec-title"
                mainText=News
                subText=最新情報
                :color=colors.news
            />

            <div id="news-content" class="sec-content">
                <div id="news-content-left">
                    <div id="req-state-card" class="card">
                        <div class="news-sec-card-title">
                            <img src="img/icon/calendar.svg">

                            <span>ご依頼・ご予約受付状況</span>
                        </div>

                        <ul class="news-sec-card-content">
                            <li v-for="(msg, i) in receptionStatusMsgList" :key=i>{{ msg }}</li>
                        </ul>
                    </div>

                    <div id="latest-info-card" class="card">
                        <div class="news-sec-card-title">
                            <img src="img/icon/bell.png">

                            <span>お知らせ</span>
                        </div>

                        <ul class="news-sec-card-content">
                            <li v-for="(article, i) in articles" :key=i>
                                <a class="article-link" :href="`article.html?id=${article.id}`">{{ article.title }}</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div id="news-content-right">
                    <a class="twitter-timeline" :href="twitterUrl" height="665px">
                        <span class="msg">Twitterのタイムラインを読み込み中</span>
                    </a>
                </div>
            </div>

            <ViewAllLink linkPath="news.html" :color=colors.news />
        </section>

        <section id="works-sec">
            <SecTitle
                id="works-sec-title"
                mainText=Works
                subText=制作実績
                :color=colors.works
            />

            <div id="works-sec-content" class="sec-content">
                <HoriSlider id="work-sec-slider" :gap="cardGap">
                    <SliderCell
                        v-for="(producedModel, i) in producedModels"
                        :key=i
                    >
                        <ModelCard
                            :id=producedModel.id
                            :bgPanelImgPath=producedModel.bgPanelImgPath
                            :portrateImgPath=producedModel.portrateImgPath.fronts[0]
                            :isBig="isModelBig"
                        />
                    </SliderCell>
                </HoriSlider>

                <ViewAllLink
                    linkPath="works.html"
                    :color=colors.works
                />
            </div>
        </section>

        <section id="info-sec">
            <SecTitle
                id="info-sec-title"
                mainText=Information
                subText=ご案内
                :color=colors.info
            />

            <div id="info-sec-content" class="sec-content">
                <IconTextColCardHoriSlider  id="info-sec-slider" :datas=infoSecCardDatas />

                <ViewAllLink
                    linkPath="info.html"
                    :color=colors.info
                />
            </div>
        </section>

        <section id="about-sec">
            <SecTitle
                id="about-sec-title"
                mainText=About
                subText=Shunartについて
                :color=colors.about
            />

            <div class="sec-content">
                <IconTextColCardHoriSlider id="about-sec-slider" :datas=aboutSecCardDatas />
            </div>
        </section>

        <section id="contact-sec">
            <SecTitle
                id="contact-sec-title"
                mainText=Contact
                subText=お問い合わせ
                :color=colors.contact
            />

            <div class="sec-content">
                <IconTextColCardHoriSlider id="contact-sec-slider" :datas=contactSecCardDatas />
            </div>
        </section>
    </main>

    <CommonFooter/>
</template>

<script>
import { createClient } from 'microcms-js-sdk'
import { colors } from '@/consts/colors';
import { loadProducedModelDefine } from "@/scripts/producedModelDefineLoader.js"
import { infoPageList } from '@/consts/infoPageList';
import { mailLinkClickHandler } from '@/scripts/mailLinkClickHandler';
import CommonHeader from '@/components/CommonHeader.vue'
import CommonFooter from '@/components/CommonFooter.vue'
import IconTextColCardHoriSlider from '@/components/IconTextColCardHoriSlider.vue';
import SecTitle from '@/components/SecTitle.vue';
import ReqLink from '@/components/ReqLink.vue';
import ModelCard from '@/components/ModelCard.vue';
import ViewAllLink from '@/components/ViewAllLink.vue';
import HoriSlider from '@/components/HoriSlider.vue';
import SliderCell from '@/components/SliderCell.vue';

export default {
    name: 'HomePage',
    components: {
        CommonHeader,
        CommonFooter,
        IconTextColCardHoriSlider,
        SecTitle,
        ReqLink,
        ModelCard,
        ViewAllLink,
        HoriSlider,
        SliderCell,
    },
    data () {
        return {
            colors: colors,
            homeHeroPath: "img/homeHero.png",
            receptionStatusMsgList: [],
            articles: [],
            twitterUrl: "",
            producedModels: [],
            infoSecCardDatas: [],
            aboutSecCardDatas: [
                {
                    iconPath: "img/icon/note.svg",
                    text: "Studio Shunartとは",
                    href: "about.html#top-anchor"
                },
                {
                    iconPath: "img/icon/userSquare.svg",
                    text: "プロフィール",
                    href: "about.html#profile-anchor"
                },
                {
                    iconPath: "img/icon/brushWriting.svg",
                    text: "スキル",
                    href: "about.html#skills-anchor"
                }
            ],
            contactSecCardDatas: [
                {
                    iconPath: "img/icon/cardEdit.svg",
                    text: "お見積りのご相談",
                    href: "planCatSelect.html"
                },
                {
                    id: "mail-card",
                    iconPath: "img/icon/messages.svg",
                    text: "お問い合わせ"
                },
            ]
        }
    },
    methods: {
        mailLinkClickHandler: mailLinkClickHandler,
        initTwTimeline: async function() {
            // twitterのタイムラインの元になる情報
            const contactAddessUrl = "editable/contactAddress.json";
            const contactAddress = await (await fetch(contactAddessUrl)).json();
            this.twitterUrl = contactAddress.twitterUrl;

            const twTimelineScript = document.createElement('script');
            twTimelineScript.setAttribute('src', 'https://platform.twitter.com/widgets.js');
            twTimelineScript.setAttribute('async', 'true');
            document.head.appendChild(twTimelineScript);
        }
    },
    computed: {
        cardGap() {
            return window.innerWidth <= 1280 ? "10px" : "30px";
        },
        isModelBig() {
            return window.innerWidth <= 1280;
        }
    },
    async created() {
        // 受付情報
        const receptionStatusMsgListUrl = "editable/receptionStatusMsgList.json";
        this.receptionStatusMsgList = await (await fetch(receptionStatusMsgListUrl)).json();

        // お知らせ
        const client = createClient({
            serviceDomain: process.env.VUE_APP_MICROCMS_DOMAIN,
            apiKey: process.env.VUE_APP_MICROCMS_API_KEY,
        });
        this.articles = (await client.get({ endpoint: 'articles' })).contents
            .slice(0, 3);

        // 制作実績一覧
        this.producedModels = await loadProducedModelDefine();

        this.infoSecCardDatas = infoPageList.map((infoPage) => {
            return {
                iconPath: infoPage.iconImgPath,
                text: infoPage.title,
                href: infoPage.path,
            }
        });
    },
    mounted() {
        document.getElementById("mail-card").addEventListener("click", this.mailLinkClickHandler);
        this.initTwTimeline();
    },
}
</script>

<style lang="scss">
@import url("@/assets/css/common.css");

main {
    overflow-x: hidden;
}

#hero-area {
    width: 100%;
    height: 850px;
    margin-bottom: 20px;
    object-fit: cover;
}

.req-link {
    margin-bottom: 20px;
}

/* セクション共通設定↓ */
section {
    margin: 0 0 50px 55px;
    width: 100%;
}

.sec-content {
    margin: 50px 55px 0  0;
}
/* セクション共通設定↑ */

#news-content {
    display: flex;
    justify-content: center;
}

#news-content-left {
    width: 785px;
    margin-right: 87px;
}

.news-sec-card-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.news-sec-card-title span {
    font-size: 32px;
}

.news-sec-card-title img {
    margin-right: 13px;
}

.news-sec-card-content {
    list-style: inside;

    li {
        margin: 15px;
    }

    .article-link {
        text-decoration: underline;
    }
}

#req-state-card {
    margin-bottom: 65px;
    padding: 30px;

    color: var(--gray7);
}

#req-state-card .news-sec-card-title img {
    width: 42px;
    height: 42px;
}

#latest-info-card {
    padding: 30px;

    color: var(--gray7);
}

#latest-info-card .news-sec-card-title img {
    width: 28px;
    height: 32px;
}

#news-content-right {
    width: 475px;
    height: 665px;
    border: 1px solid rgb(207, 217, 222);;
    position: relative;

    .msg {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        text-align: center;
        transform: translateY(-50%);
    }
}

#info-sec .card {
    width: 387px;
    height: 330px;
}

#about-sec .card {
    width: 387px;
    height: 330px;
}

#contact-sec .card {
    width: 611px;
    height: 342px;
}

@media screen and (max-width: 1280px) {
    /* セクション共通設定↓ */
    section {
        margin: 0 0 30px 40px;
    }

    .sec-content {
        margin: 15px 40px 0  0;
    }
    /* セクション共通設定↑ */

    #hero-area {
        height: 417px;
    }

    #news-content-left {
        margin-right: 0;

        #req-state-card, #latest-info-card {
            padding: 10px 15px;
            border-radius: 5px;

            .news-sec-card-content li {
                /* はみ出した文字は...にする の複数行バージョン */
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2; /* 表示行数 */
                overflow: hidden;

                &::before {
                    content: "・";
                }
            }
        }

        #req-state-card {
            margin-bottom: 12px;
        }

        .news-sec-card-title span {
            font-size: 14px;
        }

        .news-sec-card-title img {
            width: 18px;
            height: 18px;
            margin-right: 4px;
        }

        .news-sec-card-content {
            li {
                font-size: 11px;
            }
        }

        .news-sec-card-content li {
            margin: 6px 0;
        }
    }

    #news-content-right {
        display: none;
    }

    #work-sec-slider {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    #info-sec .card {
        width: 160px;
        height: 140px;
    }

    #info-sec-slider {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    #about-sec .card {
        width: 160px;
        height: 140px;
    }

    #about-sec-slider {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    #contact-sec .card {
        width: 160px;
        height: 110px;
    }

    #contact-sec-slider {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
</style>
